import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 按需引入--
// import { Button, Select } from 'element-ui';
/* 或写为
 * Vue.use(Button)
 * Vue.use(Select)
 */
/**
 * 
 * 或者直接注册
 */
// Vue.component(Button.name, Button);
// Vue.component(Select.name, Select);

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

Vue.prototype.$screenWidth = window.innerWidth


// 延迟执行函数，控制时间间隔
function delay(fn, delay) {
  let timeout = null;
  return function () {
      // 清除上一次的定时器
      if (timeout) {
          clearTimeout(timeout);
      }
      // 设置新的定时器
      timeout = setTimeout(() => {
          fn.apply(this, arguments);
      }, delay);
  }
}

// function resize() {
//   console.log('resize executed');
//   window.location.reload()
// }
// window.onresize = delay(resize, 300);


// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
